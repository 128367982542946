import moment from "moment";
import ApiService from "../services/ApiService";
interface Production {
  id: number;
  remark: string;
  qty: string;
  dueDate: Date;
  status: string;
  dateIn: Date;
  dateOut: Date;
  cuttingStatus: string;
  cuttingDateIn: Date;
  cuttingDateOut: Date;
  peelingStatus: string;
  peelingDateIn: Date;
  peelingDateOut: Date;
  fusingStatus: string;
  fusingDateIn: Date;
  fusingDateOut: Date;
  fusingTypeName: string;
  design_id: string;
  design_data: any;
  season_id: string;
  season_data: any;
  created_by: string;
}

const CancelToken = ApiService.getInstance().CancelToken;
let cancelRequest;
const Productions: Array<Production> = [];

const deleteProduction = function (item: Production) {
  return ApiService.delete(`production/${item.id}/`);
};

const addProduction = function (item, file, id) {
  const formData = new FormData();
  delete item.image;
  const dateFieldKey: any = [
    "dueDate",
    "dateIn",
    "dateOut",
    "cuttingDateIn",
    "cuttingDateOut",
    "peelingDateIn",
    "peelingDateOut",
    "fusingDateIn",
    "fusingDateOut",
  ];
  for (const key in item) {
    if (dateFieldKey.includes(key)) {
      formData.append(key, moment(item[key]).format("YYYY-MM-DD"));
    } else {
      formData.append(key, item[key]);
    }
  }
  if (file && file[0]) {
    formData.append("image", file[0]);
  }
  if (id) {
    return ApiService.put(`production/${id}/`, formData);
  } else {
    return ApiService.post("production/", formData);
  }
};

const exportProductionData = function (searchData) {
  return new Promise((res, rej) => {
    const queryObj = {
      design_id: searchData.design_id || "",
      status: searchData.status || "",
      party_id: searchData.party_id || "",
      season_id: searchData.season_id || "",
    };
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    ApiService.csvFile(
      "export/production/?" + queryStr,
      "production-data"
    ).then((data) => res(data));
  });
};
const exportPDFProductions = function (ids) {
  return new Promise((res, rej) => {
    ApiService.pdfFile("pdf/production/?production_id=" + ids).then((data) =>
      res(data)
    );
  });
};
const productionObjClone = function () {
  return {
    id: 0,
    remark: "",
    qty: "",
    status: "",
    dueDate: new Date(),
    dateIn: new Date(),
    dateOut: new Date(),
    cuttingStatus: "",
    cuttingDateIn: new Date(),
    cuttingDateOut: new Date(),
    peelingStatus: "",
    peelingDateIn: new Date(),
    peelingDateOut: new Date(),
    fusingStatus: "",
    fusingDateIn: new Date(),
    fusingDateOut: new Date(),
    fusingTypeName: "",
    design_id: "",
    design_data: {},
    season_id: "",
    season_data: {},
    created_by: "",
  };
};

const StatusType = {
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  DISPATCH: "Dispatch",
};
const jsonToInterface = function (item: any) {
  const newItem: Production = productionObjClone();
  newItem.id = item.id;
  newItem.qty = item.qty;
  newItem.status = item.status;
  newItem.dueDate = moment(item.dueDate).toDate();
  newItem.dateIn = moment(item.dateIn).toDate();
  newItem.dateOut = moment(item.dateOut).toDate();
  newItem.cuttingStatus = item.cuttingStatus;
  newItem.cuttingDateIn = moment(item.cuttingDateIn).toDate();
  newItem.cuttingDateOut = moment(item.cuttingDateOut).toDate();
  newItem.peelingStatus = item.peelingStatus;
  newItem.peelingDateIn = moment(item.peelingDateIn).toDate();
  newItem.peelingDateOut = moment(item.peelingDateOut).toDate();
  newItem.fusingStatus = item.fusingStatus;
  newItem.fusingDateIn = moment(item.fusingDateIn).toDate();
  newItem.fusingDateOut = moment(item.fusingDateOut).toDate();
  newItem.fusingTypeName = item.fusingTypeName;
  newItem.remark = item.remark;
  newItem.design_id = item.design_id;
  newItem.design_data = item.design_data;
  newItem.season_id = item.season_id;
  newItem.season_data = item.season_data;
  newItem.created_by = item.created_by;
  return newItem;
};
const getProductionById = function (id) {
  return new Promise((res, rej) => {
    ApiService.get(`production/${id}`).then(function (response) {
      res(jsonToInterface(response.data));
    });
  });
};

const getProductions = function (
  pageNumber = 1,
  searchData,
  status = "",
  user = ""
) {
  return new Promise((res, rej) => {
    const limit = 10;
    const queryObj = {
      page: pageNumber,
      limit: limit,
      offset: (pageNumber - 1) * limit,
      search: searchData.name || "",
      design_id: searchData.design_id || "",
      status: searchData.status || "",
      party_id: searchData.party_id || "",
      season_id: searchData.season_id || "",
      created_by: user,
    };
    console.log(queryObj);
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken.source();
    ApiService.getWithoutSlash(`production/?${queryStr}`, cancelRequest)
      .then(function (response) {
        const resObj: any = {
          count: 0,
          data: [],
        };
        resObj.count = response.data.count;
        if (response.data && response.data.results) {
          resObj.data = response.data.results.map((item) => {
            return jsonToInterface(item);
          });
        }
        console.log("resObj", resObj);
        res(resObj);
      })
      .catch((error) => rej(error));
  });
};

const getProductionCuttingsCount = function (
  status = "",
  production_id = "",
  design_material_item_id = ""
) {
  return new Promise((res, rej) => {
    const queryObj = {
      production_id: production_id,
      design_material_item_id: design_material_item_id,
    };
    console.log(queryObj);
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken.source();
    ApiService.getWithoutSlash(`production/count/?${queryStr}`, cancelRequest)
      .then(function (response) {
        res(response.data);
      })
      .catch((error) => rej(error));
  });
};
const jsonToInterfaceProduction = jsonToInterface;
const getEmptyObjOfProduction = productionObjClone;
export {
  Production,
  getProductions,
  deleteProduction,
  addProduction,
  getProductionById,
  jsonToInterfaceProduction,
  productionObjClone,
  exportProductionData,
  exportPDFProductions,
  getEmptyObjOfProduction,
  getProductionCuttingsCount,
  StatusType,
};

export default Productions;
