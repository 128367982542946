import moment from "moment";
import ApiService from "../services/ApiService";
interface DesignMaterial {
  id: number;
  cuttingCost: string;
  cuttingTime: string;
  width: string;
  height: string;
  material_id: string;
  material_data: any;
  design_id: string;
  design_data: any;
  created_by: string;
}

const CancelToken = ApiService.getInstance().CancelToken;
let cancelRequest;
const DesignMaterials: Array<DesignMaterial> = [];

const deleteDesignMaterial = function (item: DesignMaterial) {
  return ApiService.delete(`design-material/${item.id}/`);
};

const addDesignMaterial = function (item, id) {
  delete item.created_by
  if (id) {
    return ApiService.put(`design-material/${id}/`, item);
  } else {
    return ApiService.post("design-material/", item);
  }
};

const exportDesignMaterialData = function (ids) {
  return new Promise((res, rej) => {
    ApiService.csvFile(
      "export/design-material/?design=" + ids,
      "designMaterial-data"
    ).then((data) => res(data));
  });
};
const exportPDFDesignMaterials = function (ids) {
  return new Promise((res, rej) => {
    ApiService.pdfFile("pdf/design-material/?design=" + ids).then((data) =>
      res(data)
    );
  });
};
const designMaterialObjClone = function () {
  return {
    id: 0,
    cuttingTime: "",
    cuttingCost: "",
    width: "",
    height: "",
    material_id: "",
    material_data: {},
    design_id: "",
    design_data: {},
    created_by: "",
  };
};
const jsonToInterface = function (item: any) {
  const newItem: DesignMaterial = designMaterialObjClone();
  newItem.id = item.id;
  newItem.cuttingTime = item.cuttingTime;
  newItem.cuttingCost = item.cuttingCost;
  newItem.width = item.width;
  newItem.height = item.height;
  newItem.material_id = item.material_id;
  newItem.material_data = item.material_data;
  newItem.design_id = item.design_id;
  newItem.design_data = item.design_data;
  newItem.created_by = item.created_by;
  return newItem;
};
const getDesignMaterialById = function (id) {
  return new Promise((res, rej) => {
    ApiService.get(`design-material/${id}`).then(function (response) {
      res(jsonToInterface(response.data));
    });
  });
};

const getDesignMaterials = function (
  pageNumber = 1,
  searchData,
  status = "",
  design_id = "",
  limit = 10
) {
  return new Promise((res, rej) => {
    const queryObj = {
      page: pageNumber,
      limit: limit,
      offset: (pageNumber - 1) * limit,
      search: searchData.name || "",
      status: status,
      design_id: design_id,
    };
    console.log(queryObj);
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken.source();
    ApiService.getWithoutSlash(`design-material/?${queryStr}`, cancelRequest)
      .then(function (response) {
        const resObj: any = {
          count: 0,
          data: [],
        };
        resObj.count = response.data.count;
        if (response.data && response.data.results) {
          resObj.data = response.data.results.map((item) => {
            return jsonToInterface(item);
          });
        }
        console.log("resObj", resObj);
        res(resObj);
      })
      .catch((error) => rej(error));
  });
};

const jsonToInterfaceDesignMaterial = jsonToInterface;
const getEmptyObjOfDesignMaterial = designMaterialObjClone;
export {
  DesignMaterial,
  getDesignMaterials,
  deleteDesignMaterial,
  addDesignMaterial,
  getDesignMaterialById,
  jsonToInterfaceDesignMaterial,
  designMaterialObjClone,
  exportDesignMaterialData,
  exportPDFDesignMaterials,
  getEmptyObjOfDesignMaterial
};

export default DesignMaterials;
