
import { defineComponent, ref, onMounted, computed } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import router from "@/router";
import DesignMaterials, {
  exportDesignMaterialData,
  exportPDFDesignMaterials,
} from "@/core/data/designMaterial";
import {
  DesignMaterial,
  getDesignMaterials,
  deleteDesignMaterial,
} from "@/core/data/designMaterial";
import { getProductionCuttingsCount } from "@/core/data/production";
import { useRoute } from "vue-router";
import moment from "moment";
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getUserById, getUsers } from "@/core/data/users";
import { getError } from "@/core/helpers/utils";
import { PERMISSION_MODULE_NAME } from "@/core/data/permission";

export default defineComponent({
  name: "DesignMaterial-listing",
  components: {
    Datatable,
  },
  setup() {
    const route = useRoute();
    const checkedDesignMaterial = ref([]);
    const selectedStatus = ref("");

    let selectedItem = ref({});
    const selectedDesign = computed(() => {
      return route.params.designId;
    });
    const selectedProduciton = computed(() => {
      return route.params.id;
    });
    const total = ref<any>(0);
    const productionData = ref<any>({});
    const currentPage = ref<any>(1);
    const showSearchPanel = ref<boolean>(true);
    const selectAllDesignMaterial = ref<boolean>(false);
    const isLoading = ref<boolean>(true);
    const search = ref<string>("");
    const tableData = ref<Array<DesignMaterial>>(DesignMaterials);
    const userList = ref<any>({});
    const searchData = ref<any>({});
    const userOptionList = ref<any>([]);
    const userIdList = ref<Array<any>>([]);
    const branch = ref([]);
    const course = ref([]);
    const universiteies = ref([]);

    const cuttingProductionLanding = (id, designMaterialId) => {
      router.push({
        name: "apps-cutting-production",
        params: {
          id: id,
          designId: selectedDesign.value,
          designMaterialId: designMaterialId,
        },
      });
    };

    const peelingProductionLanding = (id, designMaterialId) => {
      router.push({
        name: "apps-peeling-production",
        params: {
          id: id,
          designId: selectedDesign.value,
          designMaterialId: designMaterialId,
        },
      });
    };
    const getListItems = function (pageNumber) {
      console.log("selectedDesign.value", selectedDesign.value);
      isLoading.value = true;
      if (pageNumber) {
        currentPage.value = pageNumber;
      }
      return new Promise((res, rej) => {
        getProductionCuttingsCount(
          "",
          selectedProduciton.value.toString(),
          selectedDesign.value.toString()
        ).then((resObj: any) => {
          productionData.value = resObj.production;
          total.value = resObj.design_materials.length;
          resObj.design_materials.forEach((item) => {
            item.production = productionData.value;
          });
          tableData.value.splice(
            0,
            tableData.value.length,
            ...resObj.design_materials
          );
          isLoading.value = false;
        });
      });
    };
    const getUserName = async function (id) {
      if (!userList.value[id]) {
        let userData = await getUserById(id);
        userList.value[id] = userData;
      }
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("DesignMaterials Listing", [
        "Apps",
        "DesignMaterials",
      ]);
      getListItems(1);
    });

    const userListComputed = computed(() => {
      return [
        { id: "", name: "All" },
        ...userOptionList.value.map((item) => ({
          name: item.name,
          ...item,
        })),
      ];
    });

    const asyncUser = (query) => {
      console.log("query", query);
      getUsers(1, query).then((resObj: any) => {
        userOptionList.value.splice(
          0,
          userOptionList.value.length,
          ...resObj.data
        );
      });
    };
    const deleteFewSites = () => {
      checkedDesignMaterial.value.forEach((item) => {
        deleteDesignMaterial(item);
      });
      checkedDesignMaterial.value.length = 0;
    };
    const exportFewSites = () => {
      exportDesignMaterialData(
        selectAllDesignMaterial.value
          ? "all"
          : checkedDesignMaterial.value.join(",")
      ).then(() => {
        Swal.fire(
          "Export!",
          "Your DesignMaterial has been exported.",
          "success"
        );
      });
    };
    const exportPDFDesignMaterialsHanlder = () => {
      exportPDFDesignMaterials(
        selectAllDesignMaterial.value
          ? "all"
          : checkedDesignMaterial.value.join(",")
      ).then(() => {
        Swal.fire(
          "Export!",
          "Your DesignMaterial has been exported.",
          "success"
        );
      });
    };
    const deleteDesignMaterialItem = (item) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteDesignMaterial(item)
            .then(() => {
              Swal.fire(
                "Deleted!",
                "Your DesignMaterial has been deleted.",
                "success"
              );
              getListItems(currentPage.value);
            })
            .catch((err) => {
              Swal.fire({
                html: getError(err),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        }
      });
    };
    const searchItems = () => {
      getListItems(1);
    };

    const setSelectedItem = (item) => {
      selectedItem.value = item;
    };

    const addDesignMaterial = (item) => {
      if (item.id) {
        router.push({
          name: "apps-material-design-details",
          params: { id: selectedDesign.value, materialId: item.id },
        });
      } else {
        router.push({
          name: "apps-material-design-add",
          params: { id: selectedDesign.value },
        });
      }
    };

    const onPageChange = (pageNumber) => {
      return getListItems(pageNumber);
    };

    const userData = computed(() => {
      return store.getters.currentUser;
    });

    const permissionData = computed(() => {
      return store.getters.currentPermission;
    });
    const hasCreate = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.DESIGN].create
      );
    });
    const hasEdit = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.DESIGN].update
      );
    });
    const hasDelete = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.PRODUCTION].delete
      );
    });
    const hasStock = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.PRODUCTION_STOCK].read
      );
    });
    const hasCost = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.PRODUCTION].show_cost
      );
    });
    const hasPeeling = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.PEELING].read
      );
    });
    const hasCutting = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.CUTTING].read
      );
    });
    const tableHeader = computed(() => {
      let columnsArr = [
        {
          name: " ",
          key: "checkbox",
          sortable: false,
        },
        {
          name: "Design Material",
          key: "design_material",
          sortable: false,
        },
        {
          name: "Cutting",
          key: "cutting",
          sortable: false,
        },
        {
          name: "Peeling",
          key: "peeling",
          sortable: false,
        },
      ];
      if (hasPeeling.value || hasCutting.value) {
        columnsArr.push({
          name: "Actions",
          key: "actions",
          sortable: false,
        });
      }
      return columnsArr;
    });

    const onClickBack = () => {
      router.back();
    };

    return {
      onClickBack,
      addDesignMaterial,
      setSelectedItem,
      selectedItem,
      tableData,
      tableHeader,
      deleteDesignMaterial,
      getDesignMaterials,
      deleteDesignMaterialItem,
      search,
      searchItems,
      checkedDesignMaterial,
      deleteFewSites,
      moment,
      total,
      currentPage,
      isLoading,
      onPageChange,
      userData,
      hasCreate,
      hasEdit,
      hasDelete,
      hasPeeling,
      hasCutting,
      selectedStatus,
      getUserName,
      userList,
      userListComputed,
      selectedDesign,
      asyncUser,
      exportFewSites,
      exportPDFDesignMaterialsHanlder,
      selectAllDesignMaterial,
      showSearchPanel,
      searchData,
      branch,
      universiteies,
      course,
      cuttingProductionLanding,
      peelingProductionLanding,
    };
  },
});
